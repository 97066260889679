import "babel-polyfill";
import "whatwg-fetch";

import "JSON2";
import "platform";
import "jquery";
import "lodash";
import "moment";
import "./moment-lang-cadmatic.js"; // must be loaded AFTER moment.js!
// AngularJS and its various extensions
import "angular";
import "angular-i18n/angular-locale_en-us.js";
import "angular-cookies";
import "angular-ui-router";
import "angular-sanitize";
// end of AngularJS and its various extensions
// Ace and its various extensions
import "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/mode-sqlserver";
import "ace-builds/src-noconflict/mode-json";
// webpack would return the url for `worker-json.js`, then we use it to configure `ace`
import jsonWorkerUrl from "file-loader?esModule=false&name=static/js/ace/worker-json.[hash:8].[ext]!ace-builds/src-noconflict/worker-json.js";
ace.config.setModuleUrl("ace/mode/json_worker", jsonWorkerUrl);
import "angular-ui-ace/src/ui-ace";
// end of Ace and its various extensions
import "bootstrap";
import "angular-ui-bootstrap";
import "angular-ui-layout";
import "ui-select";
import "jquery-minicolors";
import "angular-minicolors";
import "angular-moment"; // must be loaded AFTER Angular, Moment.js and moment-lang-cadmatic.js!
import "./angular-grid";
import "angular-vs-repeat";
import "./ng-sortable";
import "ng-file-upload";
import "toastr/toastr";
import "file-saver";
import "@timohausmann/quadtree-js";
import "./ResizeListener.js";
import "./qrcode";
import "./OpenSeadragon";
import "./jQuery-File-Upload";
